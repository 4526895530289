  
.mktoForm .mktoLabel{
    font-weight: 700;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    padding-top: 1rem !important;
    padding-bottom: 0.25rem !important;
    display:flex;
    flex-direction: row-reverse;
    width: fit-content;
    }
    
    .mktoForm{
        font-family: 'GT Walsheim',Arial,Helvetica,sans-serif !important;
    }
    .mktoForm strong{
        font-weight:inherit;
        padding:0px;
    }
    
    .mktoForm .mktoFieldWrap,.mktoForm .mktoFormCol,.mktoButtonRow{
        width:100%;
    }
    .mktoButtonRow .mktoButton{
        width: 100% !important;
        padding: 8.5px 16px !important;
        border-radius: 4px !important;
        font-size: 18px !important;
        line-height: 27px !important;
        background-color: #194bf5 !important;
        font-weight: 700 !important;
        color: #fff !important;
        border: 2px solid #194bf5 !important;
    }
    
    .mktoForm input[type=text], .mktoForm input[type=url], .mktoForm input[type=email], .mktoForm input[type=tel], .mktoForm input[type=number], .mktoForm input[type=date], .mktoForm textarea.mktoField, .mktoForm select.mktoField{
    font-size: 1rem;
        line-height: 1.5rem !important;
    padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
    border-width:1px !important;
    border-color:rgb(104 106 106/1) !important;
    width:100% !important ;
    border-radius:0.125rem  !important;
    
    }
    
    @media only screen and (max-width: 480px), only screen and (max-device-width: 480px), only screen and (max-device-height: 480px), only screen and (max-width: 768px), only screen and (max-device-width: 768px)
    {
        .mktoForm strong{
            font-weight:inherit !important;
            padding:0px !important;
        }
        .mktoForm .mktoFormCol .mktoLabel
        {
            width:fit-content !important;
        }
        .mktoForm input[type=text], .mktoForm input[type=url], .mktoForm input[type=email], .mktoForm input[type=tel], .mktoForm input[type=number], .mktoForm input[type=date], .mktoForm textarea.mktoField, .mktoForm select.mktoField{
           height:inherit !important;
            
            }
    }
    
    