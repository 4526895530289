@tailwind components;

@layer components {
  .avid-grid-layout {
    @apply grid grid-cols-2 gap-x-6 m-6 sm:grid-cols-12 lg:m-8 lg:gap-x-8 2xl:m-24 max-w-[1600px];
  }
  .btn-small *,
  .btn-primary *,
  .btn-primary-dark *,
  .btn-link *,
  .btn-link-dark *,
  .btn-link-underline *,
  .btn-link-underline-dark *,
  .btn-outline *,
  .btn-outline-dark *,
  .btn-feature *,
  .btn-feature-dark * {
    @apply font-avidwalsheimbold;
  }
  .btn-small {
    @apply !px-3 !py-1 !text-base font-bold font-avidwalsheimbold;
  }

  .btn-primary {
    @apply text-center  align-middle whitespace-nowrap focus:outline-none focus-visible:ring-[3px] ring-primary ring-offset-[3px] font-avidwalsheimbold font-bold text-lg leading-[1.6875rem] px-4 py-[8.5px] text-white bg-primary rounded-[4px] hover:bg-primary-dark hover:cursor-pointer active:bg-primary-darker disabled:bg-base-normal disabled:pointer-events-none;
  }

  .btn-primary-dark {
    @apply text-center  align-middle whitespace-nowrap focus:outline-none focus-visible:ring-[3px] ring-accent-cool ring-offset-[3px] ring-offset-current font-avidwalsheimbold font-bold text-lg leading-[1.6875rem] px-4 py-[8.5px] text-almost-black bg-accent-cool rounded-[4px] hover:bg-accent-cool-light hover:cursor-pointer active:bg-accent-cool-lighter disabled:bg-base-darker;
  }

  .btn-link {
    @apply text-center  whitespace-nowrap font-avidwalsheimbold  font-bold text-primary text-lg leading-[1.6875rem] align-middle px-4 py-[8.5px] rounded focus:outline-none focus-visible:ring-[3px] ring-primary hover:bg-base-light hover:text-primary-dark hover:cursor-pointer active:bg-base-normal active:text-primary-darker disabled:text-base-normal hover:underline underline-offset-2;
  }

  .btn-link-dark {
    @apply text-center  whitespace-nowrap font-avidwalsheimbold font-bold text-accent-cool text-lg leading-[1.6875rem] align-middle px-4 py-[8.5px] rounded focus:outline-none focus-visible:ring-[3px] ring-accent-cool hover:bg-base-darkest hover:text-accent-cool-light hover:cursor-pointer active:bg-base-darker active:text-accent-cool-lighter disabled:text-base-darker hover:underline underline-offset-2;
  }

  .btn-link-underline {
    @apply text-center align-middle whitespace-nowrap text-primary py-[8.5px] pr-4 rounded focus:outline-none focus-visible:ring-[3px] ring-primary ring-offset-[3px] font-avidwalsheimbold font-bold text-lg leading-[1.6875rem] hover:underline hover:text-primary-dark hover:cursor-pointer active:underline active:text-primary-darker underline-offset-2 disabled:text-base-normal disabled:no-underline;
  }

  .btn-link-underline-dark {
    @apply text-center align-middle whitespace-nowrap text-accent-cool py-[8.5px] pr-4 rounded focus:outline-none focus-visible:ring-[3px] ring-accent-cool ring-offset-[3px] font-avidwalsheimbold font-bold text-lg leading-[1.6875rem] hover:underline hover:text-accent-cool-light hover:cursor-pointer active:underline active:text-accent-cool-lighter underline-offset-2 disabled:text-base-normal disabled:no-underline;
  }

  .btn-outline {
    @apply text-center  whitespace-nowrap h-11 flex justify-center items-center font-avidwalsheimbold font-bold text-lg text-base-darkest align-middle leading-[1.6875rem] px-4 py-[8.5px] border-2 rounded border-base-darkest hover:cursor-pointer hover:text-white hover:bg-base-darker hover:border-base-darker active:bg-base-darkest active:border-base-darkest disabled:text-base-normal disabled:border-base-normal focus-visible:outline-none focus-visible:ring-[3px] ring-primary ring-offset-[3px];
  }

  .btn-outline-dark {
    @apply text-center  align-middle whitespace-nowrap flex justify-center items-center font-avidwalsheimbold font-bold text-lg text-base-lightest leading-[1.6875rem] px-4 py-[8.5px] h-11 border-2 rounded border-base-lightest hover:cursor-pointer hover:text-base-darker hover:bg-base-lightest hover:border-base-darker active:bg-base-darkest active:border-base-darkest disabled:text-base-normal disabled:border-base-normal focus:outline-none focus-visible:ring-[3px] ring-avidgreen ring-offset-[3px];
  }

  .btn-feature {
    @apply text-center  align-middle whitespace-nowrap focus:outline-none focus-visible:ring-[3px] ring-primary ring-offset-[3px] font-avidwalsheimbold font-bold text-lg leading-[1.6875rem] px-4 py-[8.5px] text-white bg-secondary rounded-[4px] hover:bg-secondary-dark hover:cursor-pointer active:bg-secondary-darker disabled:bg-base-normal;
  }

  .btn-feature-dark {
    @apply text-center  align-middle whitespace-nowrap focus:outline-none focus-visible:ring-[3px] ring-white ring-offset-almost-black ring-offset-[3px] font-avidwalsheimbold font-bold text-lg leading-[1.6875rem] px-4 py-[8.5px] text-almost-black bg-white rounded-[4px] hover:bg-base-light hover:cursor-pointer active:bg-base-normal disabled:bg-base-darkest;
  }
  /*styles for blipsbanner component */
  .blipsbanner .btn-feature,
  .blipsbanner .btn-feature-dark,
  .blipsbanner .btn-link,
  .blipsbanner .btn-link-dark,
  .blipsbanner .btn-outline,
  .blipsbanner .btn-outline-dark,
  .blipsbanner .btn-primary,
  .blipsbanner .btn-primary-dark {
    @apply !no-underline;
  }

  .input-text {
    @apply rounded-sm h-[38px] px-3 pt-2 pb-[6px] border text-almost-black border-base-darker placeholder:text-opacity-60 invalid:border-danger invalid:border-2 valid:border-success valid:border-2 read-only:bg-base-light;
  }

  .input-text-no-validation {
    @apply rounded-sm h-[38px] px-3 pt-2 pb-[6px] border text-almost-black border-base-darker placeholder:text-opacity-60 read-only:bg-base-light;
  }

  .custom-radio-button-tailwind {
    @apply appearance-none h-6 w-6 grid place-content-center hover:bg-primary-lighter focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-primary before:content-none before:h-5 before:w-5 before:rounded-full before:bg-white before:border-2 before:border-almost-black hover:before:bg-primary-lighter disabled:pointer-events-none checked:before:border-primary checked:before:bg-primary checked:before:shadow-[0_0_0_-3px_rgb(255,255,255)] hover:checked:before:shadow-[0_0_0_-3px_rgb(209,219,253)];
  }

  .nav-link {
    @apply font-bold focus:rounded focus-visible:rounded focus:outline-accent-cool focus-visible:outline-accent-cool focus:outline focus-visible:outline focus:outline-2 focus-visible:outline-2 hover:underline hover:text-accent-cool-light active:underline active:text-accent-cool-lighter;
  }

  .input-label {
    @apply text-base font-bold pb-2;
  }

  .section-heading {
    @apply text-almost-black sm:text-[42px] sm:leading-[50px] uppercase mb-4 tracking-widest-plus;
  }

  .list-item-thumbnail {
    @apply w-[109px] h-[82px] lg:w-[128px] lg:h-[96px] xl:w-[145px] xl:h-[108px] rounded bg-grayishblue;
  }

  .mp-rich-text-with-link ul {
    @apply pb-5 pt-2;
  }

  .mp-rich-text-with-link ul li {
    @apply list-disc list-outside pl-2 ml-4 m-2;
  }

  .mp-rich-text-with-links a,
  .mp-rich-text-with-links a:link {
    @apply text-mp-txt-primary hover:underline hover:cursor-pointer active:underline active:text-primary-darker underline-offset-2 disabled:no-underline;
  }

  .mp-rich-text-with-links h1 {
    @apply pt-3 pb-3 text-xl lg:text-2xl;
  }

  .mp-rich-text-with-links h3 {
    @apply pt-3 pb-2 text-lg lg:text-2xl;
  }

  .mp-rich-text-with-links h4 {
    @apply pt-3 pb-1 text-lg lg:text-2xl;
  }

  .richTextWithLinks ul {
    @apply pb-1 pt-2;
  }

  .richTextWithLinks li {
    @apply list-disc list-inside m-2;
  }

  .richTextWithLinks a,
  .richTextWithLinks a:link {
    @apply text-primary hover:underline hover:text-primary-dark hover:cursor-pointer active:underline active:text-primary-darker underline-offset-2 disabled:no-underline;
  }

  .richTextWithLinks p {
    @apply pt-3 pb-3 text-base-darker text-left align-top;
  }

  .richTextWithLinks h3 {
    @apply text-xl;
  }
}

.checkbox-wrapper input[type='checkbox'] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 22px;
  height: 22px;
  border-radius: 0.15em;
  border: 2px solid #222222;
  outline: none;
  cursor: pointer;
}

.checkbox-wrapper input.checked {
  background-color: #194bf5;
  border: 2px solid #194bf5;
  position: relative;
}

.checkbox-wrapper input.checked::before {
  content: '\2714';
  font-size: 22px;
  color: #fff;
  position: absolute;
  right: 0px;
  top: -3px;
}

.checkbox-wrapper input[type='checkbox']:disabled {
  border-color: #c0c0c0;
  background-color: #c0c0c0;
}

.checkbox-wrapper input[type='checkbox']:disabled + span {
  color: #c0c0c0;
}

.checkbox-wrapper input[type='checkbox']:focus {
  box-shadow: 0 0 20px #194bf5;
}
.modalShadow {
  box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.12);
}

.hero-banner-gradient {
  background: linear-gradient(
    270deg,
    rgba(10, 11, 14, 0) -0.15%,
    rgba(10, 11, 14, 0.2) 24.89%,
    rgba(10, 11, 14, 0.6) 49.93%,
    rgba(10, 11, 14, 0.85) 74.96%,
    #0a0b0e 100%
  );
}
.hero-banner-gradient.reverse {
  background: linear-gradient(
    90deg,
    rgba(10, 11, 14, 0) -0.15%,
    rgba(10, 11, 14, 0.2) 24.89%,
    rgba(10, 11, 14, 0.6) 49.93%,
    rgba(10, 11, 14, 0.85) 74.96%,
    #0a0b0e 100%
  );
}
