/*a[target='_blank']:after {
  content: '\1F5D7'
} */

/*
  Hides Sitecore Experience Editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
.scChromeData,
.scpm {
  display: none !important;
}

/*
  Styles for default JSS error components
*/
.sc-jss-editing-error,
.sc-jss-placeholder-error {
  padding: 1em;
  background-color: lightyellow;
}

.sc-jss-empty-placeholder,
.scEmptyPlaceholder {
  width: 100%;
  flex: 1;
  max-height: 200px;
  overflow: hidden;
}

/*
  Style for default content block
*/
.contentTitle {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.sc-image-wrapper {
  min-height: 100%;
}

.react-multi-carousel-item {
  display: flex;
  justify-content: center;
}
.react-multi-carousel-dot-list li:last-child button {
  margin-right: 0;
}

.react-multi-carousel-item {
  .carousel-item {
    max-width: 400px;
  }

  [class^='btn-link'] {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.bg-arrow {
  background-image: url('../../public/svg/arrow.svg');
  background-repeat: no-repeat;
  background-position: 97%;
}

ul.grid:not(.navigation) {
  li {
    a {
      color: #194bf5;
    }

    a:active,
    a:focus,
    a:hover {
      color: #007aa8;
    }

    a:focus {
      outline: thin dotted;
    }
  }
}

.clickthrough-block-item {
  h4 {
    font-size: 1.75rem;
    line-height: 2.125rem;
  }
}
/*Media Queries*/
@media screen and (max-width: 991px) {
  .clickthrough-block-item {
    h4 {
      font-size: 1.5rem;
      line-height: 1.8125rem;
    }
    a[class^='btn-link'] {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}
