/* custom radio button  */
.custom-radio-btn {
  -webkit-appearance: none;
  appearance: none;
  height: 24px;
  width: 24px;
  display: grid;
  place-content: center;
}

.custom-radio-btn:hover {
  background-color: #d1dbfd;
}

.custom-radio-btn:disabled {
  background-color: transparent;
}

.custom-radio-btn:focus-visible {
  border-radius: 2px;
  outline: 2px solid #194bf5 !important;
  outline-offset: 0px;
}

.custom-radio-btn:before,
.DR-button:before {
  content: '';
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #222222;
}

.custom-radio-btn:hover:before,
.DR-button:hover:before {
  background-color: #d1dbfd;
}

.custom-radio-btn:hover:disabled:before {
  pointer-events: none;
  background-color: white;
}

.custom-radio-btn:disabled:before {
  border: 2px solid #d1d5d7;
}

.custom-radio-btn:checked:before,
.DR-button[aria-expanded='true']:before {
  box-shadow: inset 0 0 0 3px white;
  border: 2px solid #194bf5;
  background: #194bf5;
}

.custom-radio-btn:hover:checked:before,
.DR-button[aria-expanded='true']:hover:before {
  box-shadow: inset 0 0 0 3px #d1dbfd;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #383636;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.DR-card,
.DR-show {
  border-top: none !important;
  border-color: #a0a8bb !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.DR-button,
.DR-show {
  border-left: none !important;
  border-right: none !important;
}

.DR-show {
  padding-top: 0 !important;
}

.DR-button {
  padding: 2.25rem 0 2.25rem 0 !important;
  flex-direction: row-reverse;
  position: relative;
}

.DR-button:before {
  position: absolute;
  left: 0;
}

.DR-button[aria-expanded='true'] {
  padding-bottom: 1.75rem !important;
}

.DR-button:after {
  display: none !important;
}

.DR-button:focus-visible {
  outline: 2px solid #194bf5 !important;
  outline-offset: 5px;
  border-radius: 2px;
}

.DR-card-header .DR-logo-button:focus {
  box-shadow: none !important;
}

.DR-card:first-of-type > div > h5 > button {
  padding-top: 0 !important;
}

.DR-creditCard > div > h5 > button {
  flex-direction: row;
}

.DR-creditCard label {
  font-weight: bold;
  margin-bottom: 4px;
}

.DR-creditCard label:after {
  content: '';
  margin-left: 4px;
  margin-bottom: 4px;
  width: 12px;
  height: 12px;
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3E%3Cpath fill='rgb(202,8,19)' d='M478.21 334.093L336 256l142.21-78.093c11.795-6.477 15.961-21.384 9.232-33.037l-19.48-33.741c-6.728-11.653-21.72-15.499-33.227-8.523L296 186.718l3.475-162.204C299.763 11.061 288.937 0 275.48 0h-38.96c-13.456 0-24.283 11.061-23.994 24.514L216 186.718 77.265 102.607c-11.506-6.976-26.499-3.13-33.227 8.523l-19.48 33.741c-6.728 11.653-2.562 26.56 9.233 33.037L176 256 33.79 334.093c-11.795 6.477-15.961 21.384-9.232 33.037l19.48 33.741c6.728 11.653 21.721 15.499 33.227 8.523L216 325.282l-3.475 162.204C212.237 500.939 223.064 512 236.52 512h38.961c13.456 0 24.283-11.061 23.995-24.514L296 325.282l138.735 84.111c11.506 6.976 26.499 3.13 33.227-8.523l19.48-33.741c6.728-11.653 2.563-26.559-9.232-33.036z'/%3E%3C/svg%3E");
}

.DR-card-cvv > label {
  visibility: hidden;
  position: relative;
}

.DR-card-cvv > label:before {
  content: 'CVV';
  visibility: visible;
}

.DR-card-cvv > label:after {
  position: absolute;
  left: 32px;
  visibility: visible;
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3E%3Cpath fill='rgb(202,8,19)' d='M478.21 334.093L336 256l142.21-78.093c11.795-6.477 15.961-21.384 9.232-33.037l-19.48-33.741c-6.728-11.653-21.72-15.499-33.227-8.523L296 186.718l3.475-162.204C299.763 11.061 288.937 0 275.48 0h-38.96c-13.456 0-24.283 11.061-23.994 24.514L216 186.718 77.265 102.607c-11.506-6.976-26.499-3.13-33.227 8.523l-19.48 33.741c-6.728 11.653-2.562 26.56 9.233 33.037L176 256 33.79 334.093c-11.795 6.477-15.961 21.384-9.232 33.037l19.48 33.741c6.728 11.653 21.721 15.499 33.227 8.523L216 325.282l-3.475 162.204C212.237 500.939 223.064 512 236.52 512h38.961c13.456 0 24.283-11.061 23.995-24.514L296 325.282l138.735 84.111c11.506 6.976 26.499 3.13 33.227-8.523l19.48-33.741c6.728-11.653 2.563-26.559-9.232-33.036z'/%3E%3C/svg%3E");
}

.DR-creditCard .DR-flex-column > .DR-flex-row {
  flex-direction: row;
}

.DR-card-expiration,
.DR-card-cvv {
  margin-bottom: 0 !important;
  margin-top: 1rem !important;
}

.DR-Card-Brands {
  display: flex;
  padding-bottom: 0 !important;
}

.DR-Card-Brands > img {
  width: 44px;
  height: 30px;
  padding-bottom: 0 !important;
}

.DR-payment-method-name {
  font-weight: 700;
  font-size: 1.125rem;
  color: #222222;
  margin-left: 32px !important;
}

.DRElement {
  border-color: #454646 !important;
  border-radius: 2px;
}

.DR-card-number.DRElement {
  margin: 0;
}

.DR-pay-button.DR-button-text {
  background-color: #194bf5;
  font-size: 18px;
  font-weight: 700;
  padding: 8.5px 16px 8.5px 16px;
  height: 44px;
  width: auto;
  text-transform: none;
  margin: none;
}

@media screen and (max-width: 991px) {
  .DR-Card-Brands > img {
    width: 30px;
    height: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  .DR-creditCard .DR-flex-column > .DR-flex-row {
    flex-direction: column;
  }

  .DR-payment-method-name {
    font-size: 1rem;
  }

  .DR-card-expiration,
  .DR-card-cvv {
    width: 100% !important;
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 400px) {
  .DR-Card-Brands {
    display: grid !important;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 5px;
  }
}
