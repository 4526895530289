:root {
  /* Primary colors */
  --atomic-primary: #1372ec;
  --atomic-primary-light: #399ffe;
  --atomic-primary-dark: #1a50ad;
  --atomic-on-primary: #ffffff;
  --atomic-ring-primary: #85d80b80; /* rgba(19, 114, 236, 0.5); */

  /* Neutral colors */
  --atomic-neutral-dark: #626971;
  --atomic-neutral: #d1d5d7;
  --atomic-neutral-light: #f6f7f9;

  /* Semantic colors */
  --atomic-background: #ffffff;
  --atomic-on-background: #222222;
  --atomic-success: #12a244;
  --atomic-error: #ce3f00;
  --atomic-visited: #752e9c;
  --atomic-disabled: #c5cacf;

  /* custom component colors */
  --atomic-pill-bg: #e8edfe;
  --atomic-pill-text: #194bf5;

  /* Border radius */
  --atomic-border-radius: 0.25rem;
  --atomic-border-radius-md: 0.5rem;
  --atomic-border-radius-lg: 0.75rem;
  --atomic-border-radius-xl: 1rem;

  /* Font */
  --atomic-font-family: 'GT Walsheim Avid', -apple-system, BlinkMacSystemFont, avenir next, avenir,
    segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif; /* Following https://systemfontstack.com/ */
  --atomic-font-normal: 400;
  --atomic-font-bold: 700;

  /* Text size */
  --atomic-text-base: 0.875rem; /* 14px */
  --atomic-text-sm: 0.75rem; /* 12px */
  --atomic-text-lg: 1rem; /* 16px */
  --atomic-text-xl: 1.125rem; /* 18px */
  --atomic-text-2xl: 1.5rem; /* 24px */
  --atomic-line-height-ratio: 1.5;

  /* Layout */
  --atomic-layout-spacing-x: 1.5rem;
  --atomic-layout-spacing-y: 1rem;
}

.marketplace-search-page {
  /* Primary colors */
  --atomic-primary: #20BBD0;
  --atomic-primary-light: #20BBD0; 
  --atomic-primary-dark: #1f2127; 
  --atomic-on-primary: #ffffff;
  --atomic-ring-primary: #20BBD0; 
  /* Neutral colors */
  --atomic-neutral-dark: #626971;
  --atomic-neutral: #a0a8bb;
  --atomic-neutral-light: #1F242F;

  /* Semantic colors */
  --atomic-mp-background: #13161C; 
  --atomic-on-background: #EFF0F4; 
  --atomic-background-light: #32333b;
  --atomic-mp-bg-darker: #0A0B0E;
  --atomic-mp-bg-card: #1F242F;
  --atomic-mp-txt-bright: #EFF0F4;
  --atomic-mp-primary: #20BBD0;
  --atomic-mp-btn-primary: #0091A5;
  --atomic-mp-neutral: #A0A8BB;
  --atomic-mp-neutral-dark: #1D212A;
  --atomic-mp-neutral-medium: #3A4155;
  --atomic-mp-neutral-medium-dark: #272C38;
  --atomic-transparent: transparent;
}
