@import url('./base.css');
@import url('./components.css');
@import url('./utilities.css');
@import url('./dropin.css');
@import url('./coveo.css');
@import url('./nprogressbar.css');

.sc-jss-empty-placeholder {
  flex: 1;
}
